.facility-page-layout-v1 {
	margin-top: 98px;

	@media(max-width:767px) {
		margin-top: 93px;
	}

	.region-name {
		margin-top: -20px;
		color: var(--color-grey-dark);
	}

	.facility-map {
		width: 100%;
		height: var(--facility-map-height);
		position: relative;
	}

	.heading1 {
		padding-right: 0px;
	}

	.facility-gallery-wraper {
		display: inline-block;
		width: 100%;
	}
}