.unit-description-v1 {
	margin-top: 50px;

	.description {
		margin-bottom: 50px;
	}

	.Preishinweise {
		margin-top: 60px;
	}

	.show-more {
		font-size: var(--font-size-md);
	}
}