.unit-book-now-v1 {
	@media(min-width:992px) {
		background-color: var(--color-secondary);
		padding: 20px 15px;
		position: -webkit-sticky;
		position: sticky;
		z-index: 12;
		top: 180px;
	}

	.btn.btn-primary {
		&:hover {
			background: #fff;
			color: var(--btn-primary-bg-color);
		}
	}

	.btn.btn-default {
		&:hover {
			background: transparent;
			border: 1px solid #fff;
			color: #fff;
		}
	}
}