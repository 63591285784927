.unit-pricethirdpary-v1 {
	iframe {

		padding: 30px;
		border: 1px solid var(--widget-border-color);

		@media (min-width: 768px) {
			width: 100%;
		}

		@media (max-width: 767px) {
			overflow: auto;
			padding: 15px;
		}
	}
}