.unit-information-box-v1 {
	font-size: var(--font-size-md);

	.openstreetwarning {
		aspect-ratio: unset;
		font-size: 13px;
	}



	.map {
		padding: 15px 15px;
		background-color: transparent;
		border: 1px solid #e0ebf8;
		border-bottom: unset;

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;

			.fa {
				width: 30px;
			}

			.fa-stack {
				margin-left: -10px;
			}

		}
	}

	.stars {
		color: var(--color-stars);
	}

}