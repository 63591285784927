.footer-v1 {
	p {
		color: #fff !important;
	}

	.footer1 {
		-webkit-box-flex: 0;
		flex: 0 0 30%;
		max-width: 30%;

		@media (max-width: 767px) {
			flex: 0 0 100%;
			max-width: 100%;
		}

		@media all and (min-width: 768px) and (max-width: 1199px) {
			flex: 0 0 50%;
			max-width: 50%;
			margin-bottom: 40px !important;
		}
	}

	.footer2 {
		-webkit-box-flex: 0;
		flex: 0 0 18%;
		max-width: 18%;

		@media (max-width: 767px) {
			flex: 0 0 100%;
			max-width: 100%;
		}

		@media all and (min-width: 768px) and (max-width: 1199px) {
			flex: 0 0 50%;
			max-width: 50%;
			margin-bottom: 40px !important;
		}
	}

	.footer3 {
		-webkit-box-flex: 0;
		flex: 0 0 20%;
		max-width: 20%;

		@media (max-width: 767px) {
			flex: 0 0 100%;
			max-width: 100%;

		}

		@media all and (min-width: 768px) and (max-width: 1199px) {
			flex: 0 0 33.33%;
			max-width: 33.33%;
			margin-bottom: 40px !important;
		}
	}

	.footer4 {
		-webkit-box-flex: 0;
		flex: 0 0 20%;
		max-width: 20%;

		@media (max-width: 767px) {
			flex: 0 0 100%;
			max-width: 100%;
		}

		@media all and (min-width: 768px) and (max-width: 1199px) {
			flex: 0 0 50%;
			max-width: 50%;
			margin-bottom: 0 !important;
		}
	}

	.footer5 {
		-webkit-box-flex: 0;
		flex: 0 0 30%;
		max-width: 30%;

		@media (max-width: 767px) {
			flex: 0 0 100%;
			max-width: 100%;
		}

		@media all and (min-width: 768px) and (max-width: 1199px) {
			flex: 0 0 50%;
			max-width: 50%;
			margin-bottom: 0 !important;
		}
	}

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		padding-left: 3vw;
		padding-right: 3vw;

		@media (max-width: 767px) {
			padding-left: 5vw;
			padding-right: 5vw;
		}

		@media all and (min-width: 1200px) and (max-width: 1350px) {
			padding-left: 30px;
			padding-right: 30px;
		}

		.headline {
			font-size: 16px;
			color: var(--footer-font-color);
			font-weight: bold;
			text-transform: uppercase;
		}

		.footer-phone {
			i {
				color: #fff;
				margin: 0 7px 0 0;
			}
		}

		.v-office-logo {
			text-align: right;
			margin: 20px 0 0 0;

			img {
				margin: 20px 0 0 0 !important;
				max-width: 150px;
				width: 100%;
			}
		}


		.logo {
			max-width: 250px;
			width: 100%;
			height: auto;
			margin-top: 0;
			padding-bottom: 20px;
		}

		.openings {
			font-style: italic;

			div {
				padding-top: 10px;
				color: #fff;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
	}

}