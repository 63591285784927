.unit-social-v1 {
	.sidebar-social-buttons {
		margin-top: 25px;
		margin-bottom: 10px;

		ul {
			display: flex;
			align-items: center;
			margin-bottom: 0;

			@media (max-width: 1499px) {
				justify-content: center;
			}

			li {
				margin-right: 15px;
				margin-bottom: 15px;

				a,
				.fav-icon {
					width: 40px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: var(--color-primary);
					color: #fff;
					text-decoration: none;
					border-radius: 0;

					&:hover {
						background: var(--color-secondary);
					}
				}
			}
		}
	}
}