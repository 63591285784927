.unit-address-box-v1 {
	font-size: var(--font-size-md);

	.unit-info {
		padding: 15px 15px;
		background-color: var(--color-secondary);
		color: #fff;

		p {
			font-size: 15px;
			color: #fff;
		}

		ul {
			margin-bottom: 0;

			li {
				font-size: 15px;
				margin-bottom: 5px;
				color: #fff;

				i {
					margin-right: 5px;
				}

				a {
					color: #fff;
					text-decoration: none;

					&:hover {}
				}
			}
		}
	}
}