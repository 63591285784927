.beon-content {
	max-width: 1370px;
	margin-right: auto;
	margin-left: auto;
}

.beon-content-wrapper-text {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	border-right: 1px solid #0088923d;
	padding-right: 50px;
	margin-top: 100px;
	margin-bottom: 100px;
}

.beon-content-col2 {
	max-width: 1370px;
	margin-right: auto;
	margin-left: auto;
	display: flex;
	flex-direction: row;
	gap: 40px;
	margin-bottom: 75px;
	padding-left: 100px;
	padding-right: 100px;
	;
}

@media all and (max-width:767px) {
	.beon-content-col2 {
		flex-direction: column;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 25px;
	}
}

.beon-content-background-gradient {
	background: rgb(0, 136, 146);
	background: linear-gradient(317deg, rgba(0, 136, 146, 1) 0%, rgba(0, 136, 146, 0.09567577030812324) 30%);
	padding-top: 50px;
	padding-bottom: 75px;
	;
}

.beon-content-wrapper-col2 {
	max-width: 50%;
}

@media all and (max-width:767px) {
	.beon-content-wrapper-col2 {
		max-width: 100%;
	}
}

.img-left-col {
	max-width: 90%;
}

.img-box-shadow {
	box-shadow: 25px 25px 0px 0px #008892;
}

.beon-col-right {
	padding-top: 50px;
}

.beon-content-padding {
	padding-left: 100px;
	padding-right: 100px;
}

@media all and (max-width: 767px) {
	.beon-content-padding {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.beon-content .heading1 {
	border-bottom: none;
}

@media all and (max-width: 1300px) {
	.laptop-wrapper {
		padding: 0 50px;
	}
}

@media all and (max-width: 767px) {
	.laptop-wrapper {
		padding: 0 20px;
	}
}




/*Extern CSS*/

@import "mixin.css";

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

.flex {
	@mixin flex;
}

.size100 {
	@mixin size100;
}


html,
body {
	font-family: var(--font-family-main);
	color: var(--font-color-main);
	font-size: var(--font-size-main);
	font-weight: var(--font-weight-main);

}

h1,
.h1 {
	font-family: var(--h1-font-family);
	font-weight: var(--h1-font-weight);
	font-size: var(--h1-font-size);
	color: var(--h1-font-color);
	text-transform: var(--h1-text-transform);
	text-decoration: var(--h1-text-decoration);

}

.h1-width-small {
	line-height: var(--h1-font-size);

	small {
		font-size: var(--font-size-main);
		text-transform: none;
		display: inline-block;
	}
}

h2,
.h2 {
	font-family: var(--h2-font-family);
	font-weight: var(--h2-font-weight);
	font-size: var(--h2-font-size);
	color: var(--h2-font-color);
	text-transform: var(--h2-text-transform);
	text-decoration: var(--h2-text-decoration);
}

h3,
.h3 {
	font-family: var(--h3-font-family);
	font-weight: var(--h3-font-weight);
	font-size: var(--h3-font-size);
	color: var(--h3-font-color);
	text-transform: var(--h3-text-transform);
	text-decoration: var(--h3-text-decoration);

	&.blue {
		color: var(--color-secondary);
	}
}

h4,
.h4 {
	font-family: var(--h4-font-family);
	font-weight: var(--h4-font-weight);
	font-size: var(--h4-font-size);
	color: var(--h4-font-color);
	text-transform: var(--h4-text-transform);
	text-decoration: var(--h4-text-decoration);
}

h5,
.h5 {
	font-family: var(--h5-font-family);
	font-weight: var(--h5-font-weight);
	font-size: var(--h5-font-size);
	color: var(--h5-font-color);
	text-transform: var(--h5-text-transform);
	text-decoration: var(--h5-text-decoration);
}

.heading1 {
	display: inline-block;
	padding-right: 55px;
	border-bottom: 1px solid var(--heading1-border-color);
	margin-bottom: var(--heading1-margin-bottom);
	text-transform: var(--heading1-text-transform);
	padding-bottom: 10px;

	span.low {
		text-transform: none;
	}

	&.blue {
		color: var(--color-secondary)
	}

	@media (max-width: 767px) {
		padding-right: 0;
		word-break: break-word;
	}
}

h1.heading1.h1 {
	border-bottom: none;
}

a {
	color: var(--link-font-color);
	text-decoration: var(--link-text-decoration);
	pointer-events: unset !important;


	&:hover {
		text-decoration: var(--link-text-decoration-hover);
		color: var(--link-font-color-hover);
	}
}

p {
	word-break: break-word;

	&.blue {
		color: var(--color-secondary);
	}
}

strong {
	font-weight: 800;
}

ul {
	padding: 0;
	margin: 0 0 15px;
	list-style: none;
}

.form-control:focus {
	box-shadow: none;
}

.btn:focus,
.btn:active {
	box-shadow: none;
}

/*--- background-colors---*/

.bg-primary {
	background-color: var(--color-primary);
}

.bg-primary-light {
	background-color: var(--color-primary-light);
}

.bg-secondary {
	background-color: var(--color-secondary);
}

.bg-secondary-light {
	background-color: var(--color-secondary-light);
}

.bg-white {
	background-color: var(--color-white);
}

.bg-grey-light {
	background-color: var(--color-grey-light);
}

.bg-grey-normal {
	background-color: var(--color-grey-normal);
}

.bg-grey-dark {
	background-color: var(--color-grey-dark);
}

.bg-black {
	background-color: var(--color-black);
}

/*--- font-colors---*/

.color-primary {
	color: var(--color-primary) !important;
}

.color-primary-light {
	color: var(--color-primary-light) !important;
}

.color-secondary {
	color: var(--color-secondary) !important;
}

.color-secondary-light {
	color: var(--color-secondary-light) !important;
}

.color-white {
	color: var(--color-white) !important;
}

.color-grey-light {
	color: var(--color-grey-light) !important;
}

.color-grey-normal {
	color: var(--color-grey-normal) !important;
}

.color-grey-dark {
	color: var(--color-grey-dark) !important;
}



.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

/*--- Datepicker ---*/

.beon {

	.datepicker-trigger {

		.asd__day--disabled.asd__day--in-range {
			background: var(--cal-selection-range) !important;
			opacity: 1 !important;
			border: 1px double var(--cal-selected-border) !important;
		}

		.asd__day--enabled.asd__day--in-range {
			background: var(--cal-selection-range) !important;
			color: var(--cal-text-color-selected) !important;
			opacity: 1 !important;
			border: 1px double var(--cal-selected-border) !important;
		}


		.asd__day--disabled.asd__day--hovered {
			background: var(--cal-selection-range) !important;
			opacity: 1 !important;
			border: 1px double var(--cal-selected-border) !important;
		}

		.asd__day--enabled.asd__day--hovered {
			background: var(--cal-selection-range) !important;
			opacity: 1 !important;
			border: 1px double var(--cal-selected-border) !important;
		}

		.asd__day--enabled {
			background-color: var(--cal-available) !important;
			color: var(--cal-text-color) !important;

			&.asd__day--disabled {
				background-color: var(--cal-disabled) !important;
				opacity: 0.8 !important;
				text-decoration: underline;

				&.asd__day--selected {
					background-color: var(--cal-selected) !important;
				}

				&.asd__day--in-range {
					background: var(--cal-selection-range) !important;
					opacity: 1 !important;
					border: 1px double var(--cal-selected-border) !important;
				}

				/*	&.asd__day--hovered {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			} */
			}


		}

		.asd__day--enabled:not(.asd__day--disabled) {
			&:hover {
				background: var(--cal-selected) !important;
				opacity: 1 !important;
				color: var(--cal-text-color-hover) !important;
			}
		}

		.asd__day--selected {
			background: var(--cal-selected) !important;
			color: var(--cal-text-color-selected) !important;
			opacity: 1 !important;
			border: 1px double var(--cal-selected-border) !important;

			&.asd__selected-date-one {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					border-left: 12px solid transparent;
					border-top: 19px solid var(--cal-selection-range);
					border-bottom: 20px solid var(--cal-selection-range);
					right: 0px;
				}
			}

			&.asd__selected-date-two {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					border-right: 12px solid transparent;
					border-top: 19px solid var(--cal-selection-range);
					border-bottom: 20px solid var(--cal-selection-range);
					left: 0px;
				}
			}

		}
	}

}

/*--- Buttons ---*/

.btn {
	border-radius: var(--btn-border-radius);
	font-size: var(--btn-font-size);
	/*	padding: 8px 16px; */
	text-transform: var(--btn-text-transform);

	&.btn-primary,
	&.btn-go {
		background-color: var(--btn-primary-bg-color);
		color: var(--btn-primary-font-color);
		border-color: var(--btn-primary-border-color);

		&:hover {
			background-color: var(--btn-primary-bg-color-hover);
			border-color: var(--btn-primary-border-color-hover);
			color: var(--btn-primary-font-color-hover);
		}
	}

	&.btn-secondary {
		background-color: var(--btn-secondary-bg-color);
		color: var(--btn-secondary-font-color);
		border-color: var(--btn-secondary-border-color);

		&:hover {
			background-color: var(--btn-secondary-bg-color-hover);
			border-color: var(--btn-secondary-border-color-hover);
			color: var(--btn-secondary-font-color-hover);
		}
	}

	&.btn-default {
		background-color: var(--btn-default-bg-color);
		color: var(--btn-default-font-color);
		border-color: var(--btn-default-border-color);

		&:hover {
			background-color: var(--btn-default-bg-color-hover);
			border-color: var(--btn-default-border-color-hover);
			color: var(--btn-default-font-color-hover);
		}
	}

	&.btn-add {
		background-color: var(--btn-add-bg-color);
		color: var(--btn-add-font-color);
		border-color: var(--btn-add-border-color);

		&:hover {
			background-color: var(--btn-add-bg-color-hover);
			border-color: var(--btn-add-border-color-hover);
			color: var(--btn-add-font-color-hover);
		}
	}

	&.btn-remove {
		background-color: var(--btn-remove-bg-color);
		color: var(--btn-remove-font-color);
		border-color: var(--btn-remove-border-color);

		&:hover {
			background-color: var(--btn-remove-bg-color-hover);
			border-color: var(--btn-remove-border-color-hover);
			color: var(--btn-remove-font-color-hover);
		}
	}

	&.btn-bc {
		background-color: var(--btn-breadcrumb-bg-color);
		color: var(--btn-breadcrumb-font-color);
		border-color: var(--btn-breadcrumb-border-color);

		&:hover {
			background-color: var(--btn-breadcrumb-bg-color-hover);
			border-color: var(--btn-breadcrumb-border-color-hover);
			color: var(--btn-breadcrumb-font-color-hover);
		}
	}

	&.btn-sm {
		font-size: var(--font-size-sm);
		padding: 6px 12px;
	}

	&.btn-lg {
		height: 46px;
		font-size: var(--font-size-lg);
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}

	&.btn-arrow {
		min-width: 220px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width:992px) {
			min-width: 160px;
		}

		.fa {
			opacity: .4;
			margin-left: 15px;
		}
	}
}

.breadcrumb {
	background-color: #fff !important;
	padding-left: 0px;
	padding-bottom: 0;

}

.btn-breadcrumb .btn:not(:last-child):after {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid white;
	position: absolute;
	top: 50%;
	margin-top: -17px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid rgb(173, 173, 173);
	position: absolute;
	top: 50%;
	margin-top: -17px;
	margin-left: 1px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn {
	padding: 6px 12px 6px 24px;

	&.disabled {
		opacity: 0.6;
		color: #000;

	}
}

.btn-breadcrumb .btn:first-child {
	padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
	padding: 6px 18px 6px 24px;
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}



.breadcrumb li a:hover:after {
	border-left-color: #ffc107 !important;
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		@media (max-width:576px) {
			top: 20%;
		}




		button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: var(--color-primary) !important;
			position: absolute;
			left: 0;

			@media (max-width:991px) and (min-width:600px) {
				left: -30px;
			}

			@media (min-width:1060px) {
				left: -50px;
			}


			span {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: var(--color-white);
					font-family: "Font Awesome 5 Free";
					position: relative;
					left: 2px;
					font-size: 16px;
					top: 1px;
				}
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;

				@media (max-width:991px) and (min-width:600px) {
					right: -30px;
				}

				@media (min-width:1060px) {
					right: -50px;
				}

				/*	@media (max-width:992px) {
					right: -6px;
				} */

				span {

					&:before {
						content: "\f054";
						left: 3px;
					}
				}
			}

			&.owl-prev {
				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.single-unit {
	margin: 0 auto 10px;
	max-width: 500px;
	transition: all 0.25s ease 0s;
}

.owl-carousel.off {
	display: block;
}

.payment-view {
	margin-top: var(--page-margin-top);
	padding-top: 30px;
	padding-bottom: 60px;
}

.inner-banner {
	background-image: url(RESOURCE/img/banner-4.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 290px;
	position: relative;

	@media (max-width:992px) {
		height: 220px;
	}


	.page-title {
		position: absolute;
		bottom: 20px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;
		text-align: right;

		@media (max-width:992px) {
			bottom: 30px;
		}


		.pag-title {
			display: inline-block;
			font-size: 36px;
			margin-bottom: 0;
			color: var(--color-white);
			text-transform: uppercase;
			padding: 0 10px;
			border-bottom: 1px solid var(--color-white);

			@media (max-width:992px) {
				font-size: 24px;
			}

			span {
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
			}
		}


	}
}

.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.googleMapsHint {
	color: var(--googlemaps-hint-text-color);
	font-size: var(--googlemaps-hint-font-size);
	width: 100%;
}

.no-google-map {
	width: 100%;
	height: 150px;
	position: relative;
}

.box {
	position: absolute;
	z-index: 999 !important;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		max-width: 280px;
		justify-content: center;
		width: 100%;
		margin: 30px auto 0 !important;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 0px 10px 10px;
			max-width: 160px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}

.search-bar-content {
	.asd__wrapper {
		margin: 0 auto;
	}
}

.guestbox {
	.btn.btn-default {
		padding-bottom: 9px;
	}
}

/*@media (min-width:1200px) {
	.container {
		max-width: 1230px;
	}
}*/

@media (min-width:1500px) {
	.container {
		max-width: 1370px;
	}
}

.pswp__caption__center {
	text-align: center !important;
}

.hide-mob {
	display: block;

	@media (max-width:992px) {
		display: none;
	}
}


.hide-mobile {
	@media (max-width: 767px) {
		display: none;
	}
}

.hide-desktop {
	display: none;

	@media (max-width: 767px) {
		display: block;
	}
}

.show-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.fal {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;

}

.covid-section {

	margin: 0;
	position: absolute;
	bottom: 40px;
	right: 40px;
	z-index: 2;


	@media (max-width: 1140px) {
		right: 20px;
		top: 120px;
		bottom: inherit;
	}

	@media (max-width: 768px) {
		right: 20px;
		top: 129px;
		bottom: inherit;
	}

	@media (max-width: 560px) {
		right: 10px;
		top: 129px;
		bottom: inherit;
	}


	.btn {
		width: 160px;
		height: 160px;
		border-radius: 100px;

		@media (max-width:1170px) {
			width: 130px;
			height: 130px;
			white-space: unset;
		}

		@media (max-width: 992px) {
			width: 110px;
			height: 110px;
			font-size: 14px;

		}

		@media (max-width: 768px) {
			width: 98px;
			height: 98px;
			font-size: 13px;

		}
	}


}

.modal-dialog {
	max-width: 800px;
	margin: 30px auto;
	width: 100%;
}

.holiday-home {

	h2 {
		color: var(--color-secondary);
		padding-right: 20px !important;
		padding-left: 20px;

		@media (max-width: 767px) {
			padding-left: 0 !important;
			padding-right: 0 !important;
			word-break: unset;
		}

		@media (max-width: 374px) {
			font-size: 24px;
		}
	}

	h4 {
		color: var(--color-secondary);
	}

	.boxes {
		margin: 30px 0 0 0;

		.img-section {
			height: 150px;
			margin: 0 0 15px;
		}
	}

	.direktsuche {
		display: flex;
		padding: 5px 35px;
		border: 1px solid var(--color-primary);
		align-items: center;
		justify-content: space-between;
		border-radius: 5px;
		max-width: 530px;
		margin: 0 auto;

		label {
			margin: 0;
			line-height: normal;
			color: var(--color-primary);
			font-weight: 800;
		}

		.input-group {
			width: 100%;
			max-width: 270px;
		}

		.form-control {
			height: 40px;
			border: none;
			padding: 0;
			font-size: 15px;
		}
	}
}

.holiday-properties-main {
	background: var(--color-secondary);
	padding: 50px 0 30px 0;

	&.white {
		background: #fff;
	}

	.heading1 {
		color: #fff;
		border-bottom: 1px solid #fff;
		padding-right: 15px;
		padding-left: 15px;



	}

	.col-md-6 {
		margin-bottom: 30px;
	}

	p {
		color: #fff;
	}

	.img-part {
		img {
			width: 100%;
		}
	}

	.content-part {
		background: #fff;
		text-align: left;
		padding: 20px;

		@media all and (min-width: 993px) and (max-width: 1199px) {
			padding: 20px 10px;
		}

		p {
			color: #424343;
			font-size: 14px;
			min-height: 125px;

			@media all and (min-width: 768px) and (max-width: 992px) {
				min-height: 165px;
			}

			@media all and (min-width: 993px) and (max-width: 1199px) {
				min-height: 180px;
			}

			@media all and (min-width: 1200px) and (max-width: 1350px) {
				min-height: 145px;
			}
		}

		h3 {
			@media all and (min-width: 768px) and (max-width: 992px) {
				font-size: 18px;
			}

			@media all and (min-width: 993px) and (max-width: 1199px) {
				font-size: 17px;
			}
		}

		.btn {
			width: 100%;
			color: var(--color-primary);
			border: 1px solid var(--color-primary);

			@media all and (min-width: 768px) and (max-width: 992px) {
				font-size: 13px;
			}

			@media all and (min-width: 993px) and (max-width: 1199px) {
				font-size: 12px;
			}

			@media all and (min-width: 1200px) and (max-width: 1350px) {
				font-size: 14px;
			}

			&:hover {
				background: var(--color-primary);
				color: #fff;
			}
		}


	}
}

.section-seo-urls {
	padding: 50px 0;
	background: var(--color-secondary-dark);

	.row {
		max-width: 1000px;
		margin: 0 auto;

		.left {
			h4 {
				color: #fff;
				margin: 10px 0 0 0;
				line-height: 30px;

				@media (max-width: 767px) {
					margin: 0 0 30px 0;
					text-align: center;
				}
			}
		}

		.right {
			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				margin: 0;

				li {
					width: 33.33%;
					margin: 7px 0;

					@media (max-width: 767px) {
						width: 50%;
						text-align: center;
					}

					@media all and (min-width: 768px) and (max-width: 991px) {
						width: 50%;
						margin: 15px 0 0 0;
					}

					a {
						color: #fff;
						text-decoration: none !important;

						&:hover {
							color: var(--color-primary);
						}
					}
				}
			}
		}
	}
}

.static-view {

	margin-top: var(--page-margin-top);

	&.zusatzleistungen {
		.col-xs-1 {
			@media (max-width: 767px) {
				width: 25%;
			}
		}

		.col-xs-11 {
			@media (max-width: 767px) {
				width: 75%;
			}
		}
	}

	.scroll-to-location {
		padding-top: 120px;
		margin-top: -120px;
		display: block;
	}

	.three-img-box {
		.box-img-1 {
			height: 504px;
			object-fit: cover;

			@media (max-width: 767px) {
				height: auto;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				height: 324px;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				height: 350px;
			}

			@media all and (min-width: 1200px) and (max-width: 1499px) {
				height: 417px;
			}
		}
	}

	.static-view-content {
		padding: 50px 0;

		@media (max-width: 767px) {
			padding: 50px 0;
		}
	}

	.mob-text-center {
		@media (max-width: 767px) {
			text-align: center;
		}
	}

	.table-responsive {

		@media (max-width: 767px) {
			width: 100% !important;
		}

		td {
			padding: 10px;
		}
	}

	.img-responsive {
		max-width: 100%;
	}

	.w-100 {
		width: 100%;
	}

	.w-75 {
		width: 75%;
	}

	.w-50 {
		width: 50%;
	}

	.w-mob-100 {
		@media (max-width: 767px) {
			width: 100% !important;
		}
	}

	.min-height-150 {
		min-height: 150px;
	}

	.multiselect-container>li>a>label {
		padding: 5px 20px 5px 0;
	}

	.multiselect-container>li>a>label>input[type=checkbox] {
		opacity: 1;
		padding-left: 5px;
	}

	.multiselect {
		white-space: break-spaces;
		text-align: left;
		width: 100%;
	}

	.contact-form .btn-group {
		width: 100%;
	}

	.multiselect-container {
		padding-left: 15px !important;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.open>.dropdown-menu {
		display: block;
		width: 100%;
	}

	.dropdown-menu>.active>a,
	.dropdown-menu>.active>a:focus,
	.dropdown-menu>.active>a:hover {
		/* color: #fff; */
		text-decoration: none;
		/* background-color: #337ab7; */
		outline: 0;
		background: none;
		color: #000;
	}

	.tourformular .multiselect {
		color: rgba(27, 37, 40, 0.75);
		border: 1px solid rgba(33, 33, 33, 0.2);
		border-radius: 0;
		box-shadow: none;
		background-color: #fff !important;
		-webkit-transition: all ease .3s;
		transition: all ease .3s;
		font-size: 16px;
		font-weight: 400;
		min-height: 34px;
		line-height: 24px;
	}

	.tourformular .multiselect-container li {
		padding-left: 0px !important;
		margin-bottom: 5px !important;
		background: transparent !important;
	}

	.tourformular .multiselect-container li a {
		background: transparent !important;
	}

	.tourformular .multiselect-container li:before {
		display: none !important;
	}

	.tourformular .multiselect-container>li>a>label {
		white-space: normal;
	}

	ul.facility-list-static {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin: 20px 0;

		li {
			margin: 0 10px;
			display: inline-block;

			img {
				width: 30px;
				height: 30px;
			}
		}

	}

	ul.checklist-blue {
		li {
			margin-bottom: 15px;
			position: relative;
			padding: 0 0 0 30px;
			color: var(--color-secondary);

			&:before {
				content: "\f00c";
				font-family: FontAwesome;
				margin-left: -1.3em;
				width: 1.3em;
				color: #008892;
				position: absolute !important;
				left: 25px !important;
				top: 0;
				display: block;
			}
		}
	}

	ul.checklist-number {
		list-style: decimal;
		color: var(--color-secondary);
		padding: 0 0 0 15px;

		li {
			margin-bottom: 10px;
			position: relative;
			padding: 0 0 0 0;
			color: var(--color-secondary);

			a {
				color: var(--color-secondary);

				&:hover {
					color: var(--color-primary);
				}

			}
		}
	}

	ul.list {
		list-style: disc;
		padding: 0 0 0 20px;

		li {
			margin: 0 0 10px 0;
		}
	}

	&.komplett1-view {
		ul.list {
			list-style: disc;
			padding: 0 0 0 20px;

			li {
				margin: 0 0 10px 0;
				color: var(--color-secondary);
				list-style: number;
			}
		}

		ul.list2 {
			padding: 0 0 0 20px;
			list-style: lower-latin;

			li {
				margin: 0 0 10px 0;
				color: var(--color-secondary);

			}
		}
	}
}

.pt-70 {
	padding-top: 70px !important;
}

.pb-70 {
	padding-bottom: 70px !important;
}

.pb-0 {
	padding-bottom: 0px !important;
}

.vo-index-body {
	.unit-card-v2 {
		.content {
			@media (max-width: 767px) {
				border: none;
			}
		}
	}
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/fa-light-300.eot");
	src: url("RESOURCE/fonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-light-300.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-light-300.woff") format("woff"),
		url("RESOURCE/fonts/fa-light-300.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Asap-Regular';
	src: url('RESOURCE/fonts/Asap-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Asap-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Asap-Regular.svg#Asap-Regular') format('svg'),
		url('RESOURCE/fonts/Asap-Regular.eot'),
		url('RESOURCE/fonts/Asap-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Asap-Regular.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

.fa.fa-heart-o {
	font-family: "Font Awesome 5 Free";
	font-weight: 400;
}

.fa-envelope-o:before {
	content: "\f003";
}

.fa-phone:before {
	content: "\f095";
}

.checkbox input[type=checkbox]:checked+label:after {
	font-family: "Font Awesome 5 Free" !important;
	font-weight: 600 !important;
}

.asd__month {
	min-height: 330px;
}

.fa.fa-facebook,
.fa.fa-twitter {
	font-family: "Font Awesome 5 Brands";
	font-weight: 400;
}

.wustrow-view {
	margin-top: 0;


	.static-view-content {
		padding-top: 0;
	}

	.wustrow-banner {
		height: 600px;
		margin-bottom: 80px !important;
		position: relative;

		@media (max-width: 576px) {
			height: auto;
		}

		@media (max-width: 767px) {
			margin-bottom: 50px !important;
		}

		.searchbar-v1 {
			bottom: 50px;
			top: unset;

			@media (max-width: 576px) {
				bottom: 0;
				top: unset;
			}
		}

		.wustrow-banner-img {
			background-image: url(RESOURCE/img/slider-1.jpg);
			position: relative;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			width: 100%;
			height: 600px;

			@media (max-width: 576px) {
				height: 490px;
			}
		}

		.hero-header {
			display: block;
			position: absolute;
			bottom: 260px;
			font-family: 'Poppins', cursive;
			width: 100%;
			z-index: 3;
			padding: 0 5px;

			.container {
				position: relative;
			}

			@media (max-width: 767px) {
				top: 200px;
			}

			@media all and (min-width: 768px) and (max-width: 992px) {
				bottom: 310px;
			}

			@media (max-height: 655px) {
				top: 190px !important;
			}

			@media (max-height: 600px) {
				top: 150px !important;
				bottom: unset !important;
			}


			@media all and (min-width: 768px) and (max-width: 991px) {}

			@media (max-width:576px) {
				top: 90px !important;
				height: 400px;
				padding-top: 170px;
			}

			.hero-text {
				text-align: center;
				width: 100%;

				.hero-headline {
					width: 100%;
				}

				h1 {
					font-family: var(--hero-header-font-family);
					color: #fff;
					text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
					font-weight: 600;
					margin-bottom: 0px;
					font-size: 50px;
					line-height: 60px;
					max-width: 860px;
					width: 100%;
					margin: 0 auto;

					/* @media (min-width: 1600px) {
					font-size: 80px;
				} */

					@media (max-width: 1140px) {
						font-size: 50px;
					}

					@media (max-width: 992px) {
						font-size: 45px;
						line-height: 55px;
					}

					@media (max-width: 767px) {
						font-size: 25px;
						line-height: 35px;
						max-width: 450px;
						width: 100%;
					}

					@media (max-height: 600px) {
						font-size: 20px;
						line-height: 30px;
					}

					@media (max-width:576px) {
						font-size: 30px;
						line-height: 40px;
					}

					@media all and (min-width: 768px) and (max-width: 991px) {
						padding-left: 0;
						font-size: 40px;
						line-height: 50px;
					}

					@media all and (min-width: 992px) and (max-width: 1199px) {
						padding-left: 0;
					}
				}

				.hero-subtitle {
					font-family: var(--hero-header-sub-title-font-family);
					font-size: 2.5rem;
					color: #fff;
					text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

					@media (min-width: 1600px) {
						font-size: 2.5rem;
					}

					@media (max-width: 1140px) {
						font-size: 2.0rem;
					}

					@media (max-width: 992px) {
						font-size: 2.0rem;
					}

					@media (max-width: 575px) {
						font-size: 1.5rem;
					}
				}
			}

		}
	}
}