.index-slide-show-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);


	@media (max-height: 655px) {
		min-height: 680px;
	}

	@media (max-width: 576px) {
		height: calc(100% - 305px);
		min-height: inherit !important;
	}

	.owl-carousel {
		z-index: unset;
	}

	.best-price-badge {
		position: absolute;
		width: 100%;
		top: -90px;


		@media (max-width: 767px) {
			display: none;
		}

		.container {
			position: relative;

			@media all and (min-width: 768px) and (max-width: 991px) {
				box-shadow: 0 3px 15px 0 rgb(0 0 0 / 30%);
			}

		}

		h4 {
			position: absolute;
			width: 164px;
			text-align: center;
			height: 164px;
			background: rgb(252 132 92 / 50%);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			padding: 20px;
			font-size: 18px;
			color: #fff;
			border: 1px solid #fff;
			top: 110px;
			left: 0;

			@media (max-width: 767px) {
				left: 15px;
				width: 145px;
				height: 145px;

			}

			@media (max-height: 655px) {
				left: 15px;
				width: 130px;
				height: 130px;
				font-size: 14px;
			}
		}

	}


	.index-slide {
		height: var(--index-slide-show-height);
		margin-top: var(--index-slide-show-margin-top);

		@media (max-width: 576px) {
			height: calc(100% - 315px);
		}


		.item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;

				@media (max-width: 576px) {
					height: calc(100% - 315px);
				}
			}
		}

		.slide-item {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}

		.picture {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-min-height);

			@media (max-width: 576px) {
				min-height: inherit !important;
				height: 490px;

			}

			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 655px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.slide1 {
		background-image: url(../img/slide1-1980.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hero-header {
		display: none;
		position: absolute;
		bottom: 50vh;
		font-family: 'Poppins', cursive;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		.container {
			position: relative;
		}

		@media (max-width: 767px) {
			top: 40vh;
		}

		@media (max-height: 655px) {
			top: 190px !important;
		}

		@media (max-height: 600px) {
			top: 150px !important;
			bottom: unset !important;
		}


		@media all and (min-width: 768px) and (max-width: 991px) {}

		@media (max-width:576px) {
			top: 90px !important;
			height: 400px;
			padding-top: 35%;
		}

		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			h1 {
				font-family: var(--hero-header-font-family);
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
				font-weight: 600;
				margin-bottom: 0px;
				font-size: 50px;
				line-height: 60px;
				max-width: 860px;
				width: 100%;
				margin: 0 auto;

				/* @media (min-width: 1600px) {
					font-size: 80px;
				} */

				@media (max-width: 1140px) {
					font-size: 50px;
				}

				@media (max-width: 992px) {
					font-size: 45px;
					line-height: 55px;
				}

				@media (max-width: 767px) {
					font-size: 25px;
					line-height: 35px;
					max-width: 450px;
					width: 100%;
				}

				@media (max-height: 600px) {
					font-size: 20px;
					line-height: 30px;
				}

				@media (max-width:576px) {
					font-size: 30px;
					line-height: 40px;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					padding-left: 150px;
					font-size: 40px;
					line-height: 50px;
				}

				@media all and (min-width: 992px) and (max-width: 1199px) {
					padding-left: 110px;
				}
			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.5rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

				@media (min-width: 1600px) {
					font-size: 2.5rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 2.0rem;
				}

				@media (max-width: 575px) {
					font-size: 1.5rem;
				}
			}
		}

	}
}