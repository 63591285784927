.unit-location-v1 {
	.location {
		margin-top: 0 !important;
		padding: 0 15px 15px 15px;
		background-color: transparent;
		margin-bottom: 0.5rem !important;
		border: 1px solid #e0ebf8;
		border-top: unset;

		.fa {
			margin-right: 8px;
		}
	}
}