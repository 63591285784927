:root {

	/*--- main-colors ---*/
	--color-primary: #ff6800;
	--color-primary-light: #F9DBAE;

	--color-secondary: #008892;
	--color-secondary-light: #E0EBF8;

	--color-secondary-dark: #002e38;

	--color-white: #fff;
	--color-black: #000;
	--color-grey-light: #f5f5f5;
	--color-grey-normal: #E5E4E4;
	--color-grey-dark: #747474;
	--color-red: #CC0000;

	/*--- special elements ---*/
	--color-body: var(--color-white);
	--color-stars: var(--color-primary);
	--color-checkbox-success: var(--color-primary);
	--color-photoswipe-bg: var(--color-black);

	/*--- booking-view---*/
	--booking-view-background-color: var(--color-grey-light);

	/*--- page-margin-top ---*/
	--page-margin-top: 110px;

	/*--- main font ---*/
	/* --font-family-main: 'Poppins', sans-serif; */
	--font-family-main: 'Asap-Regular', sans-serif;

	/*--- main-font-weight --- */
	--font-weight-main: 300;

	/*--- main font-color --- */
	--font-color-main: #333;

	/*--- font-sizes ---*/
	--font-size-main: 1.0rem;
	--font-size-md: 0.875rem;
	--font-size-sm: 0.75rem;
	--font-size-xs: 0.625rem;

	/*--- titles --- */
	--title-font-family-main: var(--font-family-main);
	--title-font-color-main: #000;
	--title-font-weight-main: 600;
	--title-text-transform-main: none;
	--title-text-decoration-main: none;

	--h1-font-size: 2.25rem;
	--h1-font-family: var(--title-font-family-main);
	--h1-font-color: var(--title-font-color-main);
	--h1-font-weight: var(--title-font-weight-main);
	--h1-text-transform: var(--title-text-transform-main);
	--h1-text-decoration: var(--title-text-decoration-main);

	--h2-font-size: 1.5rem;
	--h2-font-family: var(--title-font-family-main);
	--h2-font-color: var(--title-font-color-main);
	--h2-font-weight: var(--title-font-weight-main);
	--h2-text-transform: var(--title-text-transform-main);
	--h2-text-decoration: var(--title-text-decoration-main);

	--h3-font-size: 1.25rem;
	--h3-font-family: var(--title-font-family-main);
	--h3-font-color: var(--title-font-color-main);
	--h3-font-weight: var(--title-font-weight-main);
	--h3-text-transform: var(--title-text-transform-main);
	--h3-text-decoration: var(--title-text-decoration-main);

	--h4-font-size: 1.125rem;
	--h4-font-family: var(--title-font-family-main);
	--h4-font-color: var(--title-font-color-main);
	--h4-font-weight: var(--title-font-weight-main);
	--h4-text-transform: var(--title-text-transform-main);
	--h4-text-decoration: var(--title-text-decoration-main);

	--h5-font-size: 1.0rem;
	--h5-font-family: var(--title-font-family-main);
	--h5-font-color: var(--title-font-color-main);
	--h5-font-weight: var(--title-font-weight-main);
	--h5-text-transform: var(--title-text-transform-main);
	--h5-text-decoration: var(--title-text-decoration-main);

	--heading1-border-color: var(--color-secondary);
	--heading1-margin-bottom: 2.0rem;
	--heading1-text-transform: uppercase;

	/*--- links --- */
	--link-font-color: var(--color-primary);
	--link-font-color-hover: var(--color-primary);
	--link-text-decoration: none;
	--link-text-decoration-hover: underline;

	/*--- buttons ---*/
	--btn-font-size: var(--font-size-main);
	--btn-border-radius: 5px;
	--btn-text-transform: none;

	--btn-primary-font-size: var(--btn-font-size);
	--btn-primary-bg-color: var(--color-primary);
	--btn-primary-bg-color-hover: var(--color-secondary);
	--btn-primary-border-color: var(--color-primary);
	--btn-primary-border-color-hover: var(--color-secondary);
	--btn-primary-border-radius: var(--btn-border-radius);
	--btn-primary-font-color: var(--color-white);
	--btn-primary-font-color-hover: var(--color-white);

	--btn-secondary-font-size: var(--btn-font-size);
	--btn-secondary-bg-color: var(--color-secondary);
	--btn-secondary-bg-color-hover: var(--color-primary);
	--btn-secondary-border-color: var(--color-secondary);
	--btn-secondary-border-color-hover: var(--color-secondary);
	--btn-secondary-border-radius: var(--btn-border-radius);
	--btn-secondary-font-color: var(--color-white);
	--btn-secondary-font-color-hover: var(--color-white);

	--btn-default-font-size: var(--btn-font-size);
	--btn-default-bg-color: var(--color-white);
	--btn-default-bg-color-hover: var(--color-secondary);
	--btn-default-border-color: var(--color-grey-dark);
	--btn-default-border-color-hover: var(--color-secondary);
	--btn-default-border-radius: var(--btn-border-radius);
	--btn-default-font-color: var(--color-black);
	--btn-default-font-color-hover: var(--color-white);

	--btn-add-font-size: var(--btn-font-size);
	--btn-add-bg-color: var(--color-primary);
	--btn-add-bg-color-hover: var(--color-secondary);
	--btn-add-border-color: var(--color-primary);
	--btn-add-border-color-hover: var(--color-secondary);
	--btn-add-border-radius: var(--btn-border-radius);
	--btn-add-font-color: var(--color-white);
	--btn-add-font-color-hover: var(--color-white);

	--btn-remove-font-size: var(--btn-font-size);
	--btn-remove-bg-color: var(--color-white);
	--btn-remove-bg-color-hover: var(--color-secondary);
	--btn-remove-border-color: var(--color-grey-dark);
	--btn-remove-border-color-hover: var(--color-secondary);
	--btn-remove-border-radius: var(--btn-border-radius);
	--btn-remove-font-color: var(--color-black);
	--btn-remove-font-color-hover: var(--color-white);

	--btn-breadcrumb-font-size: var(--btn-font-size);
	--btn-breadcrumb-bg-color: var(--color-grey-normal);
	--btn-breadcrumb-bg-color-hover: var(--color-grey-dark);
	--btn-breadcrumb-border-color: var(--color-grey-normal);
	--btn-breadcrumb-border-color-hover: var(--color-grey-dark);
	--btn-breadcrumb-border-radius: var(--btn-border-radius);
	--btn-breadcrumb-font-color: var(--color-grey-dark);
	--btn-breadcrumb-font-color-hover: var(--color-white);

	--btn-border-color-hide-show: var(--widget-header-bg-color);

	/*--- navigation --- */

	--nav-bg-color: var(--color-secondary);
	/* rgba(255,255,255,0.5); */
	--nav-border-bottom: 1px solid var(--color-primary);


	--top-nav-bg-color: transparent;
	--top-nav-font-family: var(--font-family-main);
	--top-nav-font-size: var(--font-size-md);
	--top-nav-font-color: var(--color-white);
	--top-nav-icon-size: var(--font-size-main);

	--main-nav-text-transform: uppercase;
	--main-nav-font-size: 1.0rem;
	--main-nav-mob-font-size: 0.875rem;
	--main-nav-font-color: var(--color-white);
	--main-nav-font-color-hover: var(--color-white);
	--main-nav-bg-hover: var(--color-primary);
	--main-nav-menu-bg-color: var(--color-white);

	--nav-bg-color-affix-top: rgb(0 136 146 / 50%);
	--nav-border-bottom-affix-top: none;

	--top-nav-bg-color-affix-top: transparent;
	--top-nav-font-color-affix-top: var(--top-nav-font-color);
	--top-nav-font-color-affix-top-hover: var(--top-nav-font-color-hover);

	--main-nav-font-color-affix-top: var(--main-nav-font-color);
	--main-nav-font-color-affix-top-hover: var(--main-nav-font-color-hover);
	--main-nav-bg-affix-top-hover: var(--main-nav-bg-hover);

	--main-sub-font-color: var(--font-color-main);
	--main-sub-font-color-hover: var(--color-primary);
	--main-nav-mob-menu-bg-color: var(--color-secondary);
	--main-nav-mob-font-color-hover: var(--color-white);

	/*--- index-slide-show ---*/
	--index-slide-show-height: 100vh;
	--index-slide-show-margin-top: 0px;
	--hero-header-font-family: 'Asap-Regular';
	--hero-header-sub-title-font-family: var(--font-family-main);
	--index-slide-show-min-height: 650px;

	/*--- blog ---*/

	--blog-card-title-font-size: var(--font-size-main);
	--blog-card-title-font-color: var(--font-color-main);

	/*--- faq ---*/
	--faq-title-bg-color: var(--color-secondary-light);
	--faq-title-font-color: var(--font-color-main);

	/*--- footer ---*/
	--footer-bg-color: #006a74;
	--footer-border-top-color: var(--color-primary);
	--footer-font-color: var(--color-white);
	--footer-btn-bg-color: var(--color-secondary);
	--footer-icon-color: var(--color-white);
	--footer-copyright-bg-color: #002e38;
	--footer-copyright-font-color: var(--color-white);


	/*--- theme-cards ---*/
	--overlay-color: rgba(0, 0, 0, 0.55);
	--theme-card-text-color: var(--white);
	--theme-card-title: var(--h2-font-size);
	--theme-card-description: var(--font-size-md);

	/*--- widget ---*/
	--widget-header-bg-color: var(--color-secondary-light);
	--widget-header-title-font-color: var(--h2-font-color);
	--widget-border-color: var(--widget-header-bg-color);
	--widget-block-title-font-color: var(--h3-font-color);


	/*--- unit-navbar ---*/
	--unit-navbar-bg-color: var(--color-grey-light);
	--unit-navbar-font-size-item: var(--font-size-main);
	--unit-navbar-font-color-item: var(--color-black);
	--unit-navbar-font-color-item-hover: var(--color-white);
	--unit-navbar-bg-color-item-hover: var(--color-primary);

	/*--- calendar colors ---*/
	--unit-cal-not-available-bg: #006a74;
	--unit-cal-not-available-color: #fff;

	--unit-cal-disabled-bg: #fff;
	--unit-cal-disabled-color: var(--color-grey-dark);

	/* --unit-click-not-possible-bg: #E2F1FB; */
	--unit-click-not-possible-bg: #fff;
	--unit-click-not-possible-color: #00;

	--unit-click-possible-bg: #fff;
	--unit-click-possible-color: var(--color-black);

	--unit-cal-selected: #0770BA;
	--unit-cal-selection-range: #00c4ce;
	--unit-cal-selected-border: #fff;
	--unit-cal-text-color: #333;
	--unit-cal-text-color-hover: white;
	--unit-cal-text-color-selected: white;

	/* --- datepicker  colors */
	--cal-not-available: #F8CCCF;
	--cal-disabled: #d8dcdd;
	--cal-available: var(--unit-cal-available);
	--cal-selected: var(--unit-cal-selected);
	--cal-selection-range: var(--unit-cal-selection-range);
	--cal-selected-border: var(--unit-cal-selected-border);
	--cal-text-color: var(--unit-cal-text-color);
	--cal-text-color-hover: var(--unit-cal-text-color-hover);
	--cal-text-color-selected: var(--unit-cal-text-color-selected);

	/*--- spacing ---*/
	--section-main-mb: 60px;
	--unit-section-mb: var(--section-main-mb);

	/*--- googleMaps ---*/
	--unit-map-height: 380px;
	--facility-map-height: var(--unit-map-height);

	/*--- googleMaps hint ---*/
	--googlemaps-hint-font-size: var(--font-size-md);
	--googlemaps-hint-text-color: var(--color-red);


	/*--- about us ---*/
	--about-us-grid-width: 250px;

	/*--- contact ---*/
	--contact-sidebar-bg-color: var(--color-secondary-light);


}


@media (max-width: 768px) {
	:root {
		--about-us-grid-width: 200px;
	}
}

@media (max-width: 992px) {
	:root {
		--page-margin-top: 110px;
		--h1-font-size: 1.75rem;
		--h2-font-size: 1.6rem;
		--h3-font-size: 1.25rem;
		--h4-font-size: 1.125rem;
	}
}

@media (max-width: 767px) {
	:root {
		--page-margin-top: 100px;
	}
}