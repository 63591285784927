.unit-rooms-and-beds-v1 {
	position: relative;

	.hide-show-btn {
		position: absolute;
		bottom: -14px;
		left: 50%;
		transform: translate(-50%, 0);
		padding: 5px 15px;
		background-color: var(--white);
		display: inline-block;
		border: 1px solid var(--btn-border-color-hide-show);
		font-size: var(--font-size-md);

		&:focus,
		&:hover {
			text-decoration: none;
		}

		&:after {
			content: "\f107";
			margin-left: 10px;
			margin-left: 5px;
			font-family: fontawesome;
		}
	}

	.showLessRoomdtls {
		.unit-room-details-v1 {
			display: none;

			&:first-child {
				display: block;
			}
		}
	}

	.showFullRoomdtls {
		.unit-room-details-v1 {
			display: block;
		}

		.hide-show-btn:after {
			content: "\f106";
		}
	}


	.unit-room-details-v1 {
		.room {
			font-weight: 600;

			@media (max-width:992px) {
				font-size: var(--font-size-md);
			}

		}


		.room-details {
			ul {

				li {
					padding-left: 30px;
					position: relative;

					@media (max-width:992px) {
						font-size: var(--font-size-md);
					}


					&:before {
						content: "\f00c";
						font-family: "Font Awesome 5 Free";
						-webkit-font-smoothing: antialiased;
						font-weight: 600;
						display: inline-block;
						position: absolute;
						left: 0;
						top: 0;
						color: var(--color-primary);
					}
				}
			}
		}
	}
}