.unit-page-layout-v1 {
	margin-top: 96px;

	@media (max-width: 767px) {
		margin-top: 120px;
	}

	.video-container {
		position: relative;
		height: 0;
		padding-bottom: 56.25%;
		/* 16:9 Format */
		margin-bottom: 50px;

		iframe {
			position: absolute;
			top: 40px;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.video-hint {
		position: relative;
		background-color: #000;
		aspect-ratio: 16/9;
		padding: 20px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: column;


		@media(max-width:500px) {
			padding: 5px;
			font-size: 13px;
			flex-direction: row;
		}

		.text {
			text-align: center;
		}

		.button-line {
			text-align: center;
			margin: 10px 0px;

			@media(max-width:500px) {
				margin: 0px 0px;
			}
		}
	}

	.left-part {
		@media all and (min-width: 992px) and (max-width: 1499px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.right-part {
		@media all and (min-width: 992px) and (max-width: 1499px) {
			display: none !important;
		}
	}

	.unit-map-main {


		.widget-block {
			border-bottom: unset;
		}

		@media all and (min-width: 992px) and (max-width: 1499px) {
			display: block !important;
		}
	}

	.unit-book-main {
		@media all and (min-width: 992px) and (max-width: 1499px) {
			display: block !important;
		}
	}

	.left-part {
		@media (min-width: 1500px) {
			-webkit-box-flex: 0;
			flex: 0 0 72%;
			max-width: 72%;
		}
	}

	.right-part {
		@media (min-width: 1500px) {
			-webkit-box-flex: 0;
			flex: 0 0 28%;
			max-width: 28%;
		}
	}

	.booking-mobile {
		display: none;

		@media (max-width: 1499px) {
			display: block;
		}

		.unit-booking-v1 {


			.widget-header {
				border-bottom: unset;
			}

			.col-md-12 {
				@media all and (min-width: 768px) and (max-width: 1499px) {
					flex: 0 0 50%;
					max-width: 50%;
				}
			}
		}

	}

	.unit-map-main {
		.sidebar-social-buttons {
			margin-top: 25px;
			margin-bottom: 10px;

			ul {
				display: flex;
				align-items: center;
				margin-bottom: 0;

				li {
					margin-right: 15px;
					margin-bottom: 0;

					a {
						width: 40px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						background: var(--color-primary);
						color: #fff;
						text-decoration: none;

						&:hover {
							background: var(--color-secondary);
						}
					}
				}
			}
		}
	}

	.unit-address-box-mob-v1 {
		@media all and (min-width: 992px) and (max-width: 1499px) {
			display: block !important;
		}
	}

	.unit-social-mob-v1 {
		.unit-social-v1 {
			.sidebar-social-buttons {
				ul {
					li {
						margin-bottom: 0;
					}
				}
			}
		}

		@media all and (min-width: 992px) and (max-width: 1499px) {
			display: block !important;
		}
	}

	.unit-location-mob-v1 {
		@media all and (min-width: 992px) and (max-width: 1499px) {
			display: block !important;
		}
	}

	.unit-information-box-mob-v1 {
		@media all and (min-width: 992px) and (max-width: 1499px) {
			display: block !important;
		}

		.unit-info {
			padding: 15px 15px;
			background-color: var(--color-secondary);
			color: #fff;

			p {
				font-size: 15px;
				color: #fff;
			}

			ul {
				margin-bottom: 0;

				li {
					font-size: 15px;
					margin-bottom: 5px;
					color: #fff;

					i {
						margin-right: 5px;
					}

					a {
						color: #fff;
						text-decoration: none;

						&:hover {}
					}
				}
			}
		}

		.unit-information-box-v1 .location {
			padding: 0;
			border: none;
		}
	}
}