.unit-title-v1 {
	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}

	.facts {
		margin: 30px 0 20px 0;

		.ul-nopints {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			li {
				margin: 0 30px 10px 0;
				color: #1b2528;
				opacity: .7;

				@media (max-width: 767px) {
					width: 100%;
				}

				img {
					margin-right: 10px;
				}
			}
		}
	}

}