.contact-form-v1 {
	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}

	.alert {
		margin-bottom: 0;
	}
}